(function (factory) {
    typeof define === 'function' && define.amd ? define('adminLogins', factory) :
    factory();
}((function () { 'use strict';

    /* returns microsecond timestamp, used for queuing request responses */

    function mt() {

      var d = new Date();
      return d.getTime();
    }

    function parms(data) {
      if (data === undefined) {
        data = {};
      }

      if (typeof data === "string") {
        data += "&method=ajax&st=" + mt();
      } else {
        data.method = "ajax";
        data.st = mt();
      }

      return data;
    }
    function parse(data) {
      let results;

      if (typeof data === "string") {
        try {
          results = JSON.parse(data);
        } catch (error) {
          console.log(error);
          results = {
            code: "error"
          };
        }
      } else {
        results = data;
      }

      return results;
    }
    function handler() {
      /*jshint browser: true */
      let lU = {};
      return function (data, callback) {
        let results = parse(data);

        if (!lU[results.code] || lU[results.code] < results.st) {
          callback(results.code, results);

          switch (results.code) {
            case "login_required":
              document.getElementById("flash").innerHtml = "<div class='attn warning'>" + results.message + "</div>";
              window.location.reload(true);
          }
        }

        lU[results.code] = results.st;
      };
    }

    /*jshint jquery: true */

    function contact () {
      const CONTACT = "a.contact-button";
      const FORM_ENDPOINT = "/contact/index";
      const SUBMIT_ENDPOINT = "/contact/submit";
      const DIALOG = "#dialog";
      const DIALOG_MARKUP = "<div id='dialog'></div>";
      const FORM = "#dialog form";
      let callback;

      function show(e) {
        $.get(FORM_ENDPOINT, parms(), callback);
        e.preventDefault();
        return false;
      }

      function submit() {
        $.post(SUBMIT_ENDPOINT, parms($(FORM).serialize()), callback);
      }

      function contact_form(results) {
        if ($(DIALOG).length === 0) {
          $("body").append(DIALOG_MARKUP);
        }

        $(DIALOG).html(results.form).dialog({
          modal: true,
          resizable: false,
          width: 350
        });
        $(FORM).submit(submit);
      }

      function contact_sent(results) {
        $(DIALOG).html(results.message);
        setTimeout(function () {
          $(DIALOG).dialog("close");
        }, 3000);
      }

      function register() {
        $(CONTACT).click(show);
      }

      callback = function () {
        let handle = handler();
        return function (data) {
          handle(data, function (code, results) {
            switch (code) {
              case "contact_form":
                contact_form(results);
                break;

              case "contact_sent":
                contact_sent(results);
                break;
            }
          });
        };
      }();

      return Object.freeze({
        register
      });
    }

    /*jshint jquery: true */
    function clickthroughs () {
      function clickthrough(e) {
        if (!$(e.target).hasClass("click-target") && !$(e.target).hasClass("no-click")) {
          let dest = $(e.currentTarget).find("a.click-target").prop("href");

          if (dest !== undefined) {
            window.location = dest;
          }

          e.stopPropagation();
        }
      }

      function register() {
        $(document).on("click", ".click-area", clickthrough);
      }

      return Object.freeze({
        register
      });
    }

    /*jshint jquery: true */

    function help () {
      const HELPS = ".help-text";
      const ENDPOINT = "/help/seen";

      function handle(e) {
        let cookie_name = $(e.currentTarget).data("cookie");

        if (cookie_name !== undefined) {
          $.post(ENDPOINT, parms({
            name: cookie_name
          }));
          $(e.currentTarget).addClass("hidden");
        }
      }

      function register() {
        $(document).on("click", HELPS, handle);
      }

      return Object.freeze({
        register
      });
    }

    function bases () {
      const MARKETS_BUTTON = ".markets__button";
      const MARKETS_TOGGLE = "#markets";
      const MARKETS_STATE_KEY = "markets-open";

      function getIsOpen() {
        return window.localStorage.getItem(MARKETS_STATE_KEY) === "true";
      }

      function toggleOpen() {
        window.localStorage.setItem(MARKETS_STATE_KEY, !getIsOpen());
      }

      function initialize() {
        const toggle = document.querySelector(MARKETS_TOGGLE);
        toggle.checked = getIsOpen();
      }

      function register() {
        const buttons = document.querySelectorAll(MARKETS_BUTTON);
        buttons.forEach(button => {
          button.addEventListener('click', toggleOpen);
        });
        initialize();
      }

      return Object.freeze({
        register
      });
    }

    /*jshint jquery: true */

    let _contact = contact();

    let _clickthroughs = clickthroughs();

    let _help = help();

    let _bases = bases(); //let _daylight_savings = daylight_savings();


    $(document).ready(function () {
      _contact.register();

      _clickthroughs.register();

      _help.register();

      _bases.register(); //_daylight_savings.fix();


      $("input.date").datepicker();
    });

})));
